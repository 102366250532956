@import "./variables";
@import "./buttons";
@import '/node_modules/@infrab4a/components-b4a/src/themes/malmo.theme';

:root {
  //Instagram
  --svg-outline-next-arrow-instagram: url(./../../assets/img/svg/outline-next-arrow.svg);
  --svg-outline-previous-arrow-instagram: url(./../../assets/img/svg/outline-previous-arrow.svg);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-base;
  background: $white;
  color: $dark-700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button {
  font-family: $font-heading;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark-700;
  font-family: $font-heading;
  font-weight: 600;
}

h1 {
  font-size: $font-size-xlarge;
}

h2,
h3 {
  font-size: $font-size-large;
}

h4,
h5 {
  font-size: $font-size-xxmedium;
}

h6 {
  font-size: $font-size-xmedium;
}

button {
  cursor: pointer;
}

[disabled] {
  background-color: #6D6766 !important;
  cursor: not-allowed;
}

a {
  color: $dark-700;
  text-decoration: none;

  transition: all 0.2s linear;

  &:hover {
    color: $dark-900;
  }
}

p {
  color: $dark-500;
  font-weight: 500;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

strong {
  font-weight: 600;
  color: $dark-700;
}

// html {
//   @media (max-width: 1080px) {
//     font-size: 93.75%;
//   }

//   @media (max-width: 720px) {
//     font-size: 87.5%;
//   }
// }

/* Alerts */

.danger {
  border-color: $brown;
  color: $brown;

  font-size: $font-size-small;
}

/* Swiper */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.owl-nav {
  position: relative;
  bottom: 200px !important;
  width: 100% !important;

  .owl-prev {
    position: absolute !important;
    left: -15px !important;
    width: 40px;
    height: 40px;
    border: none;
    position: relative;
    background: #fefbf7 !important;
    background-image: url("../../assets/img/svg/outline-previous-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .owl-next {
    position: absolute !important;
    right: -15px !important;
    width: 40px;
    height: 40px;
    border: none;
    position: relative;
    background: #fefbf7 !important;
    background-image: url("../../assets/img/svg/outline-next-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  @media (max-width: 480px) {
    bottom: 240px !important;
  }
}

.owl-theme .owl-dots {
  display: flex;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  height: 36px;

  @media (max-width: 1000px) {
    display: none;
  }
}

.owl-dots {
  .owl-dot.active span {
    height: 36px !important;
    background: $brown  !important;
  }

  .owl-dot {
    margin-bottom: 15px !important;

    span {
      width: 16px !important;
      height: 16px !important;
      border-radius: 22px;

      background: $gold  !important;
      opacity: 1 !important;

      transition: all 0.2s linear !important;
    }
  }

  @media (max-width: 480px) {
    display: none !important;
  }
}
