/**
 _buttons
*/

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50px;

  font-family: $font-heading;
  font-size: $font-size-medium;

  margin: auto;

  font-weight: 600;
  line-height: 21px;
  text-align: center;

  padding: 0.5rem 1.5rem;

  outline: none;
  border: none !important;
  border-radius: 0px !important;

  transition: all 0.2s linear;

  background: transparent;
  color: $brown;

  &:hover:enabled {
    filter: brightness(0.87);
  }

  &:disabled {
    background-color: $dark-700;
    opacity: 1;
  }

  @media (max-width: 480px) {
    min-width: 163px;
    font-size: $font-size-medium;
    line-height: 28px;
  }
}

.btn-primary {
  @extend .btn;
  background: $white !important;
  color: $brown !important;
  border: 1px ridge $brown;
  font-size: $font-size-xmedium !important;
  font-weight: 600 !important;
}

.btn-secondary {
  @extend .btn;
  color: $white;
  border: 1px ridge $white;
}

.btn-tertiary {
  @extend .btn;
  color: $white !important;
  background: $brown !important;
}

.btn-outline-white {
  @extend .btn;
  background-color: $white !important;
  color: $brown !important;
  font-size: $font-size-xmedium !important;
  border: 1px solid $brown!important;
  font-weight: 600 !important;
}

.btn-outline-yellow {
  @extend .btn;
  background-color: $yellow !important;
  color: $dark-700 !important;
  font-size: $font-size-xmedium !important;
  border: 1px solid $dark-700 !important;
  font-weight: 600 !important;
}
