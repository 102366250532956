$white: #fff;
$dark-900: #000;
$dark-700: #1c1210;
$dark-600: #221d1f;
$dark-500: #443c3b;
$gold: #f3cf94;
$brown: #5a2b20;
$yellow: #FFEAC7;
$gray: #E1E1E1;
$gray-500: #464A52;

$success: #77d26c;
$danger: #da0044;

$font-heading: 'Saira Condensed', sans-serif;
$font-base: 'Open Sans', sans-serif;
$font-footer: 'Montserrat', sans-serif;

$font-size-xxlarge: 4rem;
$font-size-xlarge: 2.75rem;
$font-size-large: 2rem;
$font-size-xxmedium: 1.5rem;
$font-size-xmedium: 1.25rem;
$font-size-medium: 1.125rem;
$font-size-small: 1rem;
$font-size-xsmall: 0.75rem;



$font-size-description: 0.875rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
